import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  truco,
  trucoAccept,
  trucoRefuse,
  trucoRaise,
  updateFlipped,
} from "../../redux/actions/game";

import avatar1 from "../../assets/images/avatar/avatar1.jpg";
import avatar2 from "../../assets/images/avatar/avatar2.jpg";
import avatar3 from "../../assets/images/avatar/avatar3.jpg";
import avatar4 from "../../assets/images/avatar/avatar4.jpg";
import avatar5 from "../../assets/images/avatar/avatar5.jpg";
import avatar6 from "../../assets/images/avatar/avatar6.jpg";
import avatar7 from "../../assets/images/avatar/avatar7.jpg";
import avatar8 from "../../assets/images/avatar/avatar8.jpg";
import avatar9 from "../../assets/images/avatar/avatar9.jpg";
import avatar10 from "../../assets/images/avatar/avatar10.jpg";
import avatar11 from "../../assets/images/avatar/avatar11.jpg";
import avatar12 from "../../assets/images/avatar/avatar12.jpg";
import avatar13 from "../../assets/images/avatar/avatar13.jpg";
import avatar14 from "../../assets/images/avatar/avatar14.jpg";
import avatar15 from "../../assets/images/avatar/avatar15.jpg";
import avatar16 from "../../assets/images/avatar/avatar16.jpg";
import avatar17 from "../../assets/images/avatar/avatar17.jpg";
import avatar18 from "../../assets/images/avatar/avatar18.jpg";
import avatar19 from "../../assets/images/avatar/avatar19.jpg";
import avatar20 from "../../assets/images/avatar/avatar20.jpg";
import avatar21 from "../../assets/images/avatar/avatar21.jpg";
import avatar22 from "../../assets/images/avatar/avatar22.jpg";
import avatar23 from "../../assets/images/avatar/avatar23.jpg";
import avatar24 from "../../assets/images/avatar/avatar24.jpg";
// import avatar25 from "../../assets/images/avatar/avatar25.jpg";
// import avatar26 from "../../assets/images/avatar/avatar26.jpg";
// import avatar27 from "../../assets/images/avatar/avatar27.jpg";
// import avatar28 from "../../assets/images/avatar/avatar28.jpg";
// import avatar29 from "../../assets/images/avatar/avatar29.jpg";
import star from "../../assets/images/sheriff-badge-xp.png";
import trucoMarreco from "../../assets/voices/truco.mp3";
import "./Scoreboard.scss";

function Scoreboard() {
  const game = useSelector((state) => state.game);
  const timer = useSelector((state) => state.game.timer);
  const timerTruco = useSelector((state) => state.game.timerTruco);

  const dispatch = useDispatch();

  const { flipped } = game;

  const {
    p1,
    p2,
    next_to_play_id,
    round_points,
    truco_challenge_running,
    truco_challenger_id,
  } = game.state;

  const [avatar, setAvatar] = useState(0);

  // Dar lock nos botões durante pedido de truco, para evitar requisições dobradas
  const [buttonsLocked, setButtonsLocked] = useState(false);

  useEffect(() => {
    setAvatar(getRandomItem());
  }, []);

  const askTruco = new Audio(trucoMarreco);

  const avatarList = [
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    avatar7,
    avatar8,
    avatar9,
    avatar10,
    avatar11,
    avatar12,
    avatar13,
    avatar14,
    avatar15,
    avatar16,
    avatar17,
    avatar18,
    avatar19,
    avatar20,
    avatar21,
    avatar22,
    avatar23,
    avatar24,
    // avatar25,
    // avatar26,
    // avatar27,
    // avatar28,
    // avatar29,
  ];

  function getRandomItem() {
    const randomIndex = Math.floor(Math.random() * avatarList.length);
    const item = avatarList[randomIndex];
    return item;
  }

  function trucoChallenge() {
    askTruco.play();
    dispatch(truco());
  }

  function accept() {
    if (!buttonsLocked) {
      console.log("accept");
      setButtonsLocked(true);
      dispatch(trucoAccept());
      setTimeout(() => {
        setButtonsLocked(false);
      }, 5000);
    }
  }

  function raise() {
    if (!buttonsLocked) {
      console.log("raise");
      setButtonsLocked(true);
      dispatch(trucoRaise());
      setTimeout(() => {
        setButtonsLocked(false);
      }, 5000);
    }
  }

  function refuse() {
    if (!buttonsLocked) {
      console.log("refuse");
      setButtonsLocked(true);
      dispatch(trucoRefuse());
      setTimeout(() => {
        setButtonsLocked(false);
      }, 5000);
    }
  }

  function timerGo() {
    // askForfeit.play();
    // dispatch(timerCount());
  }

  function myTurn() {
    return next_to_play_id === p1.id;
  }

  function pointsToString(points) {
    if (p1.game_score === 11 || p2.game_score === 11) {
      return "Mão de Onze!";
    }

    switch (points) {
      case 1:
        return "Truco!";

      case 3:
        return "Seis!";

      case 4:
        return "Seis!";

      case 6:
        return "Nove!";

      case 9:
        return "Doze!";

      case 12:
        return "Doze pontos!";

      default:
        break;
    }
  }

  return (
    <span className="scoreboard">
      <img
        alt="minha-vez"
        className={`star ${myTurn() ? "you" : "opponent"}`}
        src={star}
      />
      {truco_challenge_running && truco_challenger_id === p2.id && (
        <div className="trucoMessage">
          <div className="tape">
            <span>
              <div className="truco">{pointsToString(round_points)}</div>
              <div className="buttons">
                <button disabled={buttonsLocked} onClick={accept}>
                  Aceitar
                </button>
                {round_points < 9 &&
                  p1.game_score !== 11 &&
                  p2.game_score !== 11 && (
                    <button
                      disabled={buttonsLocked || round_points >= 12}
                      onClick={raise}
                    >
                      {pointsToString(round_points + 3)}
                    </button>
                  )}
                <button disabled={buttonsLocked} onClick={refuse}>
                  Fugir
                </button>
              </div>
            </span>
          </div>
        </div>
      )}
      {truco_challenge_running && truco_challenger_id === p1.id && (
        <div className="trucoMessage">
          <div>
            <span>
              <div>
                {p1.game_score === 11 || p2.game_score === 11
                  ? "Mão de Onze!"
                  : "Seu oponente foi trucado! Ele está pensando..."}
              </div>
            </span>
          </div>
        </div>
      )}
      <div className="menu-aside">
        <div className="scoreboard">
          <div className="infos-game">
            <img alt="avatar" src={avatar} />
          </div>
          <div className="button-group">
            <section className={"western-timer-wrapper"}>
              <div className="western-timer-container">
                <button onClick={timerGo} className="western-timer">
                  {timerTruco ||
                    (truco_challenge_running === 1 && "--") ||
                    timer}
                </button>
              </div>
            </section>
          </div>
          <div className="infos-user">
            <ul className="user">
              <li>Jogador1</li>
              <li>
                {p1.name}({p1.ranking_score})
              </li>
              <li>
                {p2.name}({p2.ranking_score})
              </li>
            </ul>
            <ul className="user">
              <li>Rodada</li>
              <li>{p1.round_score}</li>
              <li>{p2.round_score}</li>
            </ul>
            <ul className="user">
              <li>Pontos</li>
              <li>{p1.game_score}</li>
              <li>{p2.game_score}</li>
            </ul>
          </div>
        </div>
        {round_points < 12 && (
          <div className="button-group">
            <section
              className={`western-button-wrapper ${
                (!myTurn() ||
                  truco_challenge_running ||
                  truco_challenger_id === p1.id) &&
                "disabled"
              }`}
            >
              <div className="western-button-container">
                <button
                  onClick={trucoChallenge}
                  disabled={
                    !myTurn() ||
                    truco_challenge_running ||
                    truco_challenger_id === p1.id
                  }
                  className="western-button"
                >
                  {(round_points === 1
                    ? "Truco! Marreco!"
                    : round_points + 3) || "Aguardando"}
                </button>
              </div>
            </section>
          </div>
        )}
        {
          <div className="button-group">
            <section
              className={`western-button-wrapper ${
                (!myTurn() || truco_challenge_running) && "disabled"
              }`}
            >
              <div className="western-button-container">
                <button
                  onClick={() => dispatch(updateFlipped())}
                  disabled={!myTurn() || truco_challenge_running}
                  className="western-button"
                >
                  {flipped ? "Mostrar" : "Esconder"}
                </button>
              </div>
            </section>
          </div>
        }
        {/* <div className="button-group">
            <section
              className={`western-button-wrapper ${
                (!myTurn() || truco_challenge_running) && "disabled"
              }`}
            >
              <div className="western-button-container">
                <button
                  onClick={refuse}
                  disabled={
                    !myTurn() ||
                    truco_challenge_running ||
                    truco_challenger_id === p1.id
                  }
                  className="western-button"
                >
                  Desistir da rodada
                </button>
              </div>
            </section>
          </div> */}
      </div>
      <header>
        <div>
          {p1.name}({p1.ranking_score})
        </div>
        <div>
          <ul>
            <li className={`${p1.round_score >= 1 ? "win" : ""}`}></li>
            <li className={`${p1.round_score >= 2 ? "win" : ""}`}></li>
            <li className="points">{p1.game_score}</li>
          </ul>
          <span> X </span>
          <ul>
            <li className="points">{p2.game_score}</li>
            <li className={`${p2.round_score >= 2 ? "win" : ""}`}></li>
            <li className={`${p2.round_score >= 1 ? "win" : ""}`}></li>
          </ul>
        </div>
        <div>
          {p2.name}({p2.ranking_score})
        </div>
      </header>
      <nav className="mobile-menu">
        <ul>
          <li>
            {timerTruco || (truco_challenge_running === 1 && "--") || timer}
          </li>
          {myTurn() &&
            round_points < 12 &&
            !truco_challenge_running &&
            truco_challenger_id !== p1.id && (
              <li onClick={trucoChallenge}>
                {(round_points === 1 ? "Truco!" : round_points + 3) ||
                  "Aguardando"}
              </li>
            )}

          {myTurn() && !truco_challenge_running && (
            <li onClick={() => dispatch(updateFlipped())}>
              {flipped ? "Mostrar" : "Esconder"}
            </li>
          )}
        </ul>
      </nav>
    </span>
  );
}

export default Scoreboard;
