import { session } from "../reducers/session";
import * as http from "../../utils/requests";
import { push } from "redux-first-history";
import store from "../store";
import toast from "react-hot-toast";

export const {
  setToken,
  removeToken,
  setBalance,
  setQueueId,
  setScore,
  setCellphone,
  setCellphoneValidated,
  setEmail,
  setNickname,
  setRole,
  setAgentCode,
  setPlayersUnderMyAgent,
} = session.actions;

export const login = (email, password) => async (dispatch) => {
  let urlencoded = new URLSearchParams();
  urlencoded.append("username", email);
  urlencoded.append("password", password);

  try {
    const response = await http.post("token", urlencoded);

    if (response.status === 200) {
      dispatch(setToken(response.data.access_token));
      dispatch(push("/"));
    }
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    } else {
      toast.error("Erro ao fazer o login.");
    }
    dispatch(push("/login"));
  }
};

export const getBalance = () => async (dispatch) => {
  try {
    const response = await http.get("users/me");

    if (response.status === 200) {
      dispatch(setBalance(response.data.balance));
      dispatch(setQueueId(response.data.queue_id));
      dispatch(setScore(response.data.score));
      dispatch(setCellphone(response.data.cellphone));
      dispatch(setCellphoneValidated(response.data.cellphone_validated));
      dispatch(setEmail(response.data.email));
      dispatch(setNickname(response.data.nickname));
      dispatch(setAgentCode(response.data.agent_code));
    }
  } catch (error) {
    if (error.response && error.response.data) {
      console.error(error.response.data.detail);
    }
  }
};

export const getNotifications = () => async (dispatch) => {
  try {
    const response = await http.get("notifications");
    if (response.status === 200 && response.data.length) {
      const { session } = store.getState();

      if (!session.token) {
        dispatch(push("/"));
      }

      const notification = response.data.find(
        (notification) => notification.name === "new_balance"
      );

      const notificationGame = response.data.find(
        (notification) => notification.name === "match_found"
      );

      const notificationGameFinished = response.data.find(
        (notification) => notification.name === "game_finished"
      );

      let scoreDiff;
      let newScore;

      // Beging calculate new ranking
      if (notificationGameFinished) {
        const responseGameFinished = JSON.parse(
          notificationGameFinished.payload_json
        );
        scoreDiff = responseGameFinished.score_diff;
        if (scoreDiff >= 0) {
          scoreDiff = `+${scoreDiff}`;
        }
        newScore = responseGameFinished.new_score;
      }

      if (notificationGame) {
        dispatch(push("game"));
      }

      if (notification) {
        const { router } = store.getState();
        let prevLocation = "";

        const oldBalance = JSON.parse(notification.payload_json).old_balance;
        const response = JSON.parse(notification.payload_json);
        const balance = response.balance;

        const amount = balance - oldBalance;

        if (router.previousLocations[1]) {
          prevLocation = router.previousLocations[1].location.pathname;
          if (prevLocation === "/game") {
            if (amount) {
              toast("Alteração no Din Din: " + amount);
            }
            if (newScore > 1600 && Number(scoreDiff) === 0) {
              toast(
                `Filas de 0 Din Din não contam pontos para jogadores com pontuação acima de 1600.`
              );
            } else if (newScore && scoreDiff) {
              toast(`Pontuação: ${newScore} (${scoreDiff})`);
            }
          }
        }
      }
    }
  } catch (error) {
    if (error.response && error.response.data) {
      // toast.error(error.response.data.detail);
    } else {
      // toast("Erro ao recuperar notificações.");
    }
  }
};

export const getPlayersUnderMyAgent = () => async (dispatch) => {
  try {
    const response = await http.get("users/me/players");
    console.log("playerUnder", response.data);
    if (response.status === 200) {
      dispatch(setPlayersUnderMyAgent(response.data));
    }
  } catch (error) {
    if (error.response && error.response.data) {
      console.error(error.response.data.detail);
    }
  }
};

export const logout = () => async (dispatch) => {
  dispatch(removeToken());
  dispatch(push("/"));
};

export const createAccount = (credentials) => async (dispatch) => {
  try {
    const payload = {
      email: credentials.email,
      nickname: credentials.nickname,
      cellphone: credentials.cellphone,
      password: credentials.password,
      agent_code: credentials.agentCode,
    };

    console.log(payload);
    const response = await http.post("users", {
      email: credentials.email,
      nickname: credentials.nickname,
      cellphone: credentials.cellphone,
      password: credentials.password,
      agent_code: credentials.agentCode,
    });

    if (response.status === 200) {
      handleAccountCreation();
      dispatch(login(credentials.email, credentials.password));
    }
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    } else {
      toast("Erro ao criar usuário.");
    }
  }
};

export const recoverPasswordSendCode = (email) => async (dispatch) => {
  try {
    const response = await http.post("users/recover/password/create", {
      email,
    });

    if (response.status === 200) {
      dispatch(push(`/redefinir-senha?email=${email}`));
    }
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    } else {
      toast("Erro ao enviar email.");
    }
  }
};

export const recoverPasswordSetNewPassword =
  (email, code, password) => async (dispatch) => {
    console.log(email, code, password);
    try {
      const response = await http.put("users/recover/password", {
        email,
        code,
        password,
      });

      if (response.status === 200) {
        dispatch(push("/login"));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.detail);
      } else {
        toast("Erro ao enviar email.");
      }
    }
  };

function handleAccountCreation() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "conversion",
    send_to: "AW-376783302/kSR-CIz55pIYEMaD1bMB",
  });
}

export const unsubscribe = () => async (dispatch) => {
  try {
    const queryParameters = new URLSearchParams(window.location.search);
    const token = queryParameters.get("token");
    const response = await http.post(`unsubscribe?token=${token}`, {});

    if (response.status === 200) {
      console.log("unsubscribe");
    }
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.detail);
    } else {
      toast("Erro ao se desinscrever");
    }
  }
};
