import React, { useState } from "react";
import "./PowerButton.scss";

function PowerButton() {
  const [logout, setLogout] = useState(false);
  return ( 
    <div id="container" onClick={() => setLogout(!logout)}>
      <svg className={`${logout? 'animated' : ''} finocomp-power-button`} version="1.1" id="Centre" x="0px" y="0px" viewBox="0 0 512 512">
        <g id="centre">
            <rect width="512" height="512" fill="none"/>
            <linearGradient id="centre-fill" gradientUnits="userSpaceOnUse" x1="313.8409" y1="175.5028" x2="197.1096" y2="336.5919">
                <stop offset="0" stopColor="#F8E45C"/>
                <stop offset="1" stopColor="#E47F21"/>
            </linearGradient>
            <path id="centre-path" d="M278.3,156.2V262c0,12.5-9.6,22.7-22,22.7c-12.4,0-22-10.2-22-22.7V156
          c-45,10.1-78.3,50.5-78.3,98.8c0,55.9,44.9,101.2,100.2,101.2S356,310.7,356,254.8C356,206.9,322.3,166.7,278.3,156.2z"/>
        </g>

        <g id="ring-1">
            <rect width="512" height="512" fill="none"/>
            <linearGradient id="ring-1-fill" gradientUnits="userSpaceOnUse" x1="347.3249" y1="129.285" x2="163.6736" y2="382.7236">
                <stop offset="0" stopColor="#F8E45C"/>
                <stop offset="1" stopColor="#E47F21"/>
            </linearGradient>
            <path id="ring-1-path" d="M278.3,100.1c0,0.1-0.2,0.1-0.2,0.2v31.1c58.8,10.6,103.1,62,103.1,123.9
          c0,69.5-56,125.9-125.2,125.9s-125.5-56.4-125.5-125.9c0-62.2,44.8-113.8,103.7-124v-30.9c0-0.1,0.3-0.2,0.3-0.3
          c-76,10.5-134.5,76-134.5,155.3c0,86.6,69.9,156.7,156.1,156.7S412,341.8,412,255.3C412,176.3,353.9,111.1,278.3,100.1z"/>
        </g>

        <g id="ring-2" width="100%" height="100%">
            <rect width="512" height="512" fill="none"/>
            <linearGradient id="ring-2-fill" gradientUnits="userSpaceOnUse" x1="354.8257" y1="74.7463" x2="114.1924" y2="406.8201">
                <stop offset="0" stopColor="#F8E45C"/>
                <stop offset="1" stopColor="#E47F21"/>
            </linearGradient>
            <path id="ring-2-path" d="M177.5,421.2C115.9,391.8,73.4,328.9,73.4,256c0-101.1,82-183.1,183.1-183.1
          c98.6,0,179,78,182.9,175.6l21.1-13C450.3,131.9,362.8,51,256.5,51c-113.2,0-205,91.8-205,205c0,77.4,42.9,144.7,106.1,179.6
          L177.5,421.2L177.5,421.2z"/>
        </g>

        <g id="ring-3">
            <rect width="512" height="512" fill="none"/>
            <linearGradient id="ring-3-fill" gradientUnits="userSpaceOnUse" x1="477.2247" y1="100.5003" x2="186.4687" y2="501.7434">
                <stop offset="0" stopColor="#F8E45C"/>
                <stop offset="1" stopColor="#E47F21"/>
            </linearGradient>
            <path id="ring-3-path" d="M334.1,12.3C334,12.3,334,12.3,334.1,12.3l-6.3,21c94,30.4,161.9,118.7,161.9,222.8
          c0,129.3-104.8,234.1-234.1,234.1c-20.9,0-41.1-2.7-60.3-7.9l-6.9,20.8c21.4,5.8,43.9,8.9,67.2,8.9c141.4,0,256-114.6,256-256
          C511.5,142,437,45.4,334.1,12.3z"/>
          
        </g>
      </svg>
    </div>
  );
}

export default PowerButton;
