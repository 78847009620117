import "./Download.scss";
import WhatsAppButton from "../../components/whatsAppButton/WhatsAppButton";
import Menu from "../../components/menuDesktop/MenuDesktop";
import MobileMenu from "../../components/menuMobile/MenuMobile";
import DiscordButton from "../../components/discordButton/DiscordButton";
import googlePlay from "../../assets/images/google-play.png";
import appStore from "../../assets/images/app-store.png";
import SideButtonDrag from "../../components/sideButtonDrag/SideButtonDrag";

const Download = () => {
  return (
    <>
      <Menu />
      <div className="background">
        <div className="button-group panel">
          <div style={{ margin: "0 7%" }}>
            <div className="div-container-general">
              <p>Baixe o aplicativo do TrucoXP no celular</p>
              <p style={{ fontSize: "12px" }}>
                Avalie o App e ganhe dinheiro para jogar! Para isso, basta
                mandar mensagem para a gente no WhatsApp com o print da
                avaliação para receber o Bônus!
              </p>
              <p style={{ fontSize: "12px" }}>
                Atualmente não temos o App para Google Play por problemas
                técnicos.
              </p>
            </div>
            {/* <a
              href="https://play.google.com/store/apps/details?id=com.trucoxp.play"
              className="backButton"
            >
              <img
                src={googlePlay}
                alt="Link para App do Truco XP no Google Play"
                style={{
                  backgroundColor: "unset",
                  marginBottom: "0px",
                  paddingBottom: "0px",
                }}
              />
            </a> */}
            <a
              href="https://apps.apple.com/us/app/truco-xp/id1662389893"
              className="backButton"
            >
              <img
                src={appStore}
                alt="Link para App do Truco Montilla no Google Play"
                style={{ backgroundColor: "unset" }}
              />
            </a>
          </div>
        </div>
        <SideButtonDrag />
        <WhatsAppButton></WhatsAppButton>
      </div>
      <MobileMenu />
    </>
  );
};

export default Download;
