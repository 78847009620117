import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./WithdrawDashboard.scss";
import payments from "../../redux/actions/payments";

const WithdrawDashboard = () => {
  const token = useSelector((state) => state.session.token);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(payments.fetchWithdrawDashboard());
  }, [dispatch]);

  const data = useSelector((state) => {
    return state.payment;
  });

  const handleWithdrawPayment = (id) => {
    dispatch(payments.withdrawPayment(id));
  };

  return (
    <>
      <div className="withdrawDashboard">
        <table id="customers">
          <tr>
            <th>Id</th>
            <th>Username</th>
            <th>Email</th>
            <th>Telefone</th>
            <th>Valor</th>
            <th>Chave Pix</th>
            <th>Deletar linha</th>
          </tr>
          {data &&
            data.withdrawDashboardData &&
            data.withdrawDashboardData.map((player, index) => (
              <tr key={index}>
                <td>{player.id}</td>
                <td>{player.username}</td>
                <td>{player.email}</td>
                <td>{player.phone}</td>
                <td>{player.value}</td>
                <td>{player.pix}</td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={handleWithdrawPayment.bind(this, player.id)}
                >
                  ❌
                </td>
              </tr>
            ))}
        </table>
      </div>
    </>
  );
};

export default WithdrawDashboard;
