import React, { useEffect, useRef } from "react";
import ReactAudioPlayer from "react-audio-player";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./Home.scss";
import logo from "../assets/images/logo.jpg";
import {
  fetchQueues,
  joinQueue,
  startSearching,
} from "../redux/actions/queues";
import { getBalance, getNotifications } from "../redux/actions/session";
import Loading from "./loading/Loading";
import WhatsAppButton from "./whatsAppButton/WhatsAppButton";
import Menu from "./menuDesktop/MenuDesktop";
import MobileMenu from "./menuMobile/MenuMobile";
import { leaveQueue } from "../redux/actions/queues";

import { setFetchQeueIntervalId } from "../redux/actions/communication";
import DiscordButton from "./discordButton/DiscordButton";
import SideButtonDrag from "./sideButtonDrag/SideButtonDrag";
import FooterPrivacidade from "./footer-privacidade/footerPrivacidade";

// v1.0
const Home = () => {
  const queues = useSelector((state) => state.queues.list);
  const queueId = useSelector((state) => state.session.queueId);
  const token = useSelector((state) => state.session.token);
  const dispatch = useDispatch();

  const shouldFetch = useRef(true);

  const handleUnload = (e) => {
    dispatch(leaveQueue());
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);

    if (shouldFetch.current) {
      shouldFetch.current = false;
      dispatch(fetchQueues());
      if (queueId) {
        dispatch(startSearching());
      }
      let fetchQeuesIntervalId = setInterval(
        () => dispatch(fetchQueues()),
        5000
      );
      if (token) {
        dispatch(getBalance());
        dispatch(getNotifications());
        dispatch(setFetchQeueIntervalId(fetchQeuesIntervalId));
      }
    }
  });

  return (
    <div className="background">
      <Menu />
      <Loading />
      <div className="button-group panel">
        <img
          className="logo logo-home"
          alt="logo"
          src={logo}
          style={{ marginTop: "30px" }}
        ></img>
        <span className="span">
          {queues.map((queue) => (
            <section key={queue.id} className="western-button-wrapper-user">
              <div className="western-button-container-user">
                <div className="peopleInQeue">{queue.users.length}</div>
                {token ? (
                  <button
                    onClick={() => dispatch(joinQueue(queue.id))}
                    className="western-button-user"
                  >
                    Valendo {queue.value} Din Din{" "}
                    {queue.value !== 0 ? `+ ${queue.rake} ` : ""}
                  </button>
                ) : (
                  <>
                    <Link to="/new-account">
                      <button className="western-button-user">
                        Valendo {queue.value} Din Din
                        {queue.value !== 0 ? `+ ${queue.rake} ` : ""}
                      </button>
                    </Link>
                  </>
                )}
              </div>
            </section>
          ))}
        </span>
      </div>

      <WhatsAppButton whatsAppClass="whatsAppClass"></WhatsAppButton>

      <MobileMenu />
      <SideButtonDrag />
      {!token && <FooterPrivacidade />}
    </div>
  );
};

export default Home;
